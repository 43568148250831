import React from 'react';
import css from './SearchBar.module.css';
import { Button, Menu, MenuContent, MenuItem, MenuLabel, NamedLink } from '../';
import { listingFields } from '../../config/configListing';

const SearchBar = () => {
  
  const allBrands1 = listingFields.find(i => i.key === 'brand')?.enumOptions || [];
  
  const links = [   
   
    {
      dropdown: false,
      component: (
      <Menu>        
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Z-Products</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key={'subcategory1'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=subcategory1` }}>Subcategory 1</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'subcategory2'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=fridge` }}>Subcategory 2</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
        </Menu>
      ),      
      search: '?pub_category=z-products',
      label: 'Z-Products',
    }, 
    {
      dropdown: false,
      component: (
      <Menu>
        
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Z-Services</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key={'category3'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=category3` }}>Category 3</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'category4'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=category4` }}>Category 4</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
        </Menu>
      ),
      search: '?pub_category=z-services',
      label: 'Z-Services',
    },
    {
      dropdown: false,
      component: (
      <Menu>
      
        
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Z-Rentals</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          
        </MenuContent>
        </Menu>
      ),
      search: '?pub_category=z-rentals',
      label: 'Z-Rentals',
    },
    {
      dropdown: false,
      component: (
      <Menu>
      
        
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Z-Sale</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          
        </MenuContent>
        </Menu>
      ),
      search: '?pub_category=z-sale',
      label: 'Z-Sale',
    },
    
    
    
  ];
  return (
    <div className={css.wrapper}>
      <div className={css.wrapperLeft}>
        {links.map(l => {
          if (l.dropdown) {
            return l.component;
          }
          else {
            return (
              <NamedLink
                className={css.link}
                name="SearchPage"
                to={{
                  search: l.search,
                }}
              >
                {l.label}
              </NamedLink>
            );
          }
          return (
            <NamedLink
              className={css.link}
              name="SearchPage"
              to={{
                search: l.search,
              }}
            >
              {l.label}
            </NamedLink>
          );
        })}
      </div>
      {/* <div className={css.wrapperRight}>
        <NamedLink name="NewListingPage" className={css.newListingLink}>
          Sell
        </NamedLink>
      </div> */}
    </div>
  );
};

export default SearchBar;
